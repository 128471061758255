<template>
  <div class="mx-2 mx-sm-5">
    
      <h1 class="text-center mb-10 text-uppercase title mb-5">{{ type.replace('-', ' & ') }} SERIES</h1>
    <v-expansion-panels focusable multiple dark hover :value="openedSeries">
    <v-expansion-panel
      v-for="series in curSeries.series" :key="series.id"
    >
      <v-expansion-panel-header>
        <h2 class="text-center title text-capitalize">{{ series.description }}</h2>
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <v-row dense align="center" justify="center">
          <v-col cols="12" sm="6" v-for="img in series.imgs" :key="img.id">
            <v-img contain :src="`../${curSeries.folder}/${img.img}`"></v-img>
          </v-col>
        </v-row>        
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  </div>

  
</template>

<script>
// @ is an alias to /src
// import axios from "axios";
import { mapState } from 'vuex';

// const APIURL = ''

export default {
  name: "Series",
  data: () => ({
    openedSeries: [0, 1],
  }),
  props: {
    type: String
  },
  computed: {
    ...mapState(['seriesDocFolder', 'seriesFaPortFolder', 'seriesDoc', 'seriesFaPort']),
    curSeries(){
      const series = {};
        switch(this.type){
            case 'documentaries':
              series.series = [...this.seriesDoc];
              series.folder = this.seriesDocFolder;
              break;
            case 'fashion-portraits':
              series.series = [...this.seriesFaPort];
              series.folder = this.seriesFaPortFolder;
              break;
            default:
              series.series = [...this.seriesDoc];
              series.folder = this.seriesDocFolder;
              break;
        }
      return series;
    },    
  },
  created(){
    // axios
    //   .all([
    //     axios.get('/api/about.php'),
    //   ])
    //   .then(
    //     axios.spread((get) => {
    //       console.log(get.data)     
    //     })
    //   )
    //   .catch(err => console.error(err));
  }, 
};
</script>
